'use client';
import { usePathname } from 'next/navigation';
import posthog from 'posthog-js';
import { PostHogProvider as BasePostHogProvider } from 'posthog-js/react';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.posthog.com',
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true,
            },
        },
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug();
        },
    });
}

export const PostHogProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const pathname = usePathname();

    useEffect(() => {
        posthog?.capture('$pageview');
    }, [pathname]);

    return <BasePostHogProvider client={posthog}>{children}</BasePostHogProvider>;
};
