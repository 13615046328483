'use client';

import { FunctionComponent } from 'react';
import { useCaptureUtms } from '../../hooks/useUtms';

export const CaptureUtms: FunctionComponent = () => {
    useCaptureUtms();

    return null;
};
